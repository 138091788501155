import React, { useContext } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import * as styles from "./Vini.module.css";
import Header from "../../Components/Header/Header";
import ViniProductCard from "../../Components/ViniProductCard/ViniProductCard";
import Footer from "../../Components/Footer/Footer";
import { graphql, Link } from "gatsby";
import TenutaBanner from "../../Components/TenutaBanner/TenutaBanner";
import BannerCard from "../../Components/BannerCard/BannerCard";
import { SEO } from "../../Components/Seo/seo"
import { languageContext } from "../../Components/Header/Header";
import { AnimationOnScroll } from 'react-animation-on-scroll';
import 'animate.css';
const Vini = ({ data }) => {
  const llanguage = useContext(languageContext);
  const { language } = llanguage;
  const localizations = data.allStrapiProductPage.edges[0]?.node?.localizations || {};
  const {DetailedInformation: localDetailedInformation, HeaderContent:localHeaderContent} = localizations?.data[0]?.attributes
  const { Description, Title, DetailedInformation, HeaderContent} =language === "it"
  ? localizations?.data[0]?.attributes
  : data?.allStrapiProductPage?.edges[0]?.node || {};
  const { edges } = data?.allStrapiProduct || {};
  
  return (
    <div style={{ overflow: "hidden" }}>
      <Header />
      <div className={styles.viniBoxWraper}>
        <TenutaBanner
          bannerTitle={Title}
          bannerDescription={Description}
          bannerImg={language === "it"
          ? localHeaderContent?.Image?.data?.attributes?.url
          : HeaderContent?.Image?.url}
          vini={true}
        />
        <div className={styles.viniHeading}>
        <AnimationOnScroll  animateIn='animate__animated animate__fadeInUp'>
          <h1 className="animate__animated animate__fadeInUp">{HeaderContent?.Title}</h1>
          </AnimationOnScroll>
          <AnimationOnScroll  animateIn='animate__animated animate__fadeInUp'>
          <p className="animate__animated animate__fadeInUp">{HeaderContent?.Description}</p>
          </AnimationOnScroll>
        </div>
        <div className={styles.viniCards}>
          {edges?.map((item) => (
            <Link
              className={styles.viniCardLink}
              to={`/ViniProductPage/${item?.node?.slug}`}
            >
              <ViniProductCard
                data={item}
                wineHeading={item?.node?.ThumbnailName}
                wineImage={item?.node?.ThumbImageVini?.url}
                hoverImage={item?.node?.ProductLogoImg?.url}
              />
            </Link>
          ))}
        </div>
        <BannerCard
          title={DetailedInformation?.Title}
          bannerImg={language === "it"
          ? localDetailedInformation?.Image?.data?.attributes?.url
          : DetailedInformation?.Image?.url}
          vini={true}
          paragraph_1={DetailedInformation?.Text1}
          paragraph_2={DetailedInformation?.Text2}
        />
      </div>
      <Footer />
    </div>
  );
};

export default Vini;

export const query = graphql`
query myQueryAndMyQuery {
  allStrapiProductPage {
    edges {
      node {
        DetailedInformation {
          Image {
            url
          }
          Text1
          Text2
          Title
        }
        HeaderContent {
          Description
          Image {
            url
          }
          Title
        }
        Title
        Description
        MetaKeyword
        MetaDescription
        localizations {
          data {
            attributes {
              DetailedInformation {
                Image {
                  data {
                    attributes {
                      url
                    }
                  }
                }
                Text1
                Text2
                Title
              }
              Description
              MetaKeyword
              MetaDescription
              HeaderContent {
                Description
                Title
                Image {
                  data {
                    attributes {
                      url
                    }
                  }
                }
              }
              Title
            }
          }
        }
      }
    }
  }
  allStrapiProduct {
    edges {
      node {
        ThumbnailName
        ThumbImageVini {
          url
        }
        ThumbnailImage {
          url
        }
        slug
        ProductLogoImg {
          url
        }
      }
    }
  }
}
`;

export const Head = ({data}) => {
  const localizations = data.allStrapiProductPage.edges[0]?.node?.localizations || {};
  const { MetaKeyword, MetaDescription } = localizations?.data[0]?.attributes|| {};
  return <SEO title={MetaKeyword} description={MetaDescription}/>
}




