import React from 'react';

const IconArrow = props => {
  // const { className } = props;
  return (
    // <svg width="30" height="30" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    //   <path d="M1.89276 11.4489L0.59304 10.142L8.46946 2.25142H2.43253L2.44673 0.454545H11.5732V9.58807H9.76207L9.77628 3.55114L1.89276 11.4489Z" fill="black" />
    // </svg>
    <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M7.785 3.344L21.945 3.248L0.585 24.608L2.457 26.48L23.769 5.168L23.721 19.472L26.313 17.12L26.265 0.991999H10.137L7.785 3.344Z" fill="black"/>
    </svg>
    
  );
};

export default IconArrow;
