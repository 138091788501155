import React from "react";
import * as styles from "./ViniProductCard.module.css";
import IconArrow from "../IconArrow/IconArrow";
// import { Link } from 'gatsby';

const ViniProductCard = (props) => {
  const {
    wineHeading,
    wineImage,
    // hoverImage
  } = props;
  return (
    <div className={styles.viniProductCardBox}>
      {/* <Link to="/ViniProductPage/ViniProductPage" className={styles.viniProductCardBox}> */}
      <div className={styles.wineCard}>
        <div className={styles.wineHeading}>
          <span>{wineHeading}</span> <IconArrow />
        </div>
        <img src={wineImage} alt="wine" />
      </div>
      {/* <span className={styles.productImage}>
        <img src={hoverImage} />
      </span> */}
      {/* </Link> */}
    </div>
  );
};

export default ViniProductCard;
